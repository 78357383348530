import React from 'react';
import getKeysForKeyboardShortcutsMode from '../utils/getKeysForKeyboardShortcutsMode';
import getKeyboardShortcutsMode from '../utils/getKeyboardShortcutsMode';
import { createHandlerMethod } from '../utils/createHandlerMethod';
import type { HotkeyCommand, Hotkeys } from '../types/HotkeyCommand';
import type { KeydownConfig } from '../types/KeydownConfig';
import type UseKeydownHandlerOptions from '../types/UseKeydownHandlerOptions';
import { Keytrap } from '../Keytrap';

let bodyKeytrap: Keytrap | undefined;

export function useGlobalHotkey(
    command: HotkeyCommand | Hotkeys,
    handler: (evt: KeyboardEvent) => void,
    options: UseKeydownHandlerOptions = {}
) {
    addBodyBinding(
        React.useMemo<KeydownConfig[]>(
            () => [{ command, handler, options }],
            [
                command,
                handler,
                options.stopPropagation,
                options.preventDefault,
                options.allowHotkeyOnTextFields,
                options.isEnabled,
            ]
        )
    );
}

export function useKeydownHandler<TElement extends HTMLElement>(
    ref: React.RefObject<TElement | null | undefined>,
    command: HotkeyCommand | Hotkeys,
    handler: (evt: KeyboardEvent) => void,
    options: UseKeydownHandlerOptions = {}
) {
    useUnifiedKeydownHandler(
        ref,
        React.useMemo(
            () => [{ command, handler, options }],
            [
                command,
                handler,
                options.stopPropagation,
                options.preventDefault,
                options.allowHotkeyOnTextFields,
                options.isEnabled,
            ]
        )
    );
}

export function useKeydownHandlers<TElement extends HTMLElement>(
    ref: React.RefObject<TElement | null | undefined>,
    getConfigs: () => KeydownConfig[]
) {
    useUnifiedKeydownHandler(
        ref,
        React.useMemo(() => getConfigs(), [getConfigs])
    );
}

export function useLazyKeydownHandler<TElement extends HTMLElement, TAttachHandle = any>(
    ref: React.RefObject<TElement | null> | undefined,
    attach: (target: TAttachHandle) => Promise<KeydownConfig[]>,
    handle: TAttachHandle
) {
    const [keydownConfigs, setKeydownConfigs] = React.useState<KeydownConfig[]>([]);
    const mouseKeytrapRef = React.useRef<
        | undefined
        | {
              keytrap: Keytrap;
              boundElement: TElement;
          }
    >();
    const keyboardShortcutsMode = getKeyboardShortcutsMode();

    React.useEffect(() => {
        let hasUnmounted = false;
        attach(handle).then(config => {
            if (!hasUnmounted) {
                setKeydownConfigs(config);
            }
        });

        return () => {
            hasUnmounted = true;
        };
    }, [attach, handle, keyboardShortcutsMode]);

    React.useEffect((): (() => void) | void => {
        const cleanupFn = ref
            ? addElementBinding(ref, keydownConfigs, mouseKeytrapRef)
            : addBodyBinding(keydownConfigs);

        return () => {
            cleanupFn?.();
        };
    }, [ref, keydownConfigs, keyboardShortcutsMode]);
}

export function getBodyKeytrap() {
    return bodyKeytrap;
}

function useUnifiedKeydownHandler<TElement extends HTMLElement>(
    ref: React.RefObject<TElement | null | undefined>,
    keydownConfigs: KeydownConfig[]
) {
    const mouseKeytrapRef = React.useRef<
        | undefined
        | {
              keytrap: Keytrap;
              boundElement: TElement;
          }
    >();
    React.useEffect(
        () => addElementBinding(ref, keydownConfigs, mouseKeytrapRef),
        [ref, keydownConfigs]
    );
}

function addElementBinding<TElement extends HTMLElement>(
    ref: React.RefObject<TElement | null | undefined>,
    keydownConfigs: KeydownConfig[],
    mouseKeytrapRef: React.MutableRefObject<
        | {
              keytrap: Keytrap;
              boundElement: TElement;
          }
        | undefined
    >
) {
    const element = ref.current;
    if (element) {
        let keytrapElementBinding: Keytrap;
        if (mouseKeytrapRef.current?.boundElement === element) {
            keytrapElementBinding = mouseKeytrapRef.current.keytrap;
        } else {
            keytrapElementBinding = new Keytrap(element);
            mouseKeytrapRef.current = {
                keytrap: keytrapElementBinding,
                boundElement: element,
            };
        }
        addKeyboardBinding(keytrapElementBinding, keydownConfigs);
        return () => {
            keytrapElementBinding?.reset();
        };
    } else {
        return () => {};
    }
}

function addBodyBinding(keydownConfigs: KeydownConfig[]) {
    if (!bodyKeytrap) {
        bodyKeytrap = new Keytrap(document.body);
    }
    addKeyboardBinding(bodyKeytrap, keydownConfigs);
    return () => {
        for (const { command } of keydownConfigs) {
            const keys = getKeysForKeyboardShortcutsMode(command);
            if (keys) {
                bodyKeytrap?.unbind(keys);
            }
        }
    };
}

function addKeyboardBinding(keytrap: Keytrap | undefined, resolvedKeydownConfigs: KeydownConfig[]) {
    for (const {
        command,
        handler,
        options: {
            stopPropagation = true,
            preventDefault = true,
            allowHotkeyOnTextFields = false,
            isEnabled = undefined,
        } = {},
    } of resolvedKeydownConfigs) {
        const keys = getKeysForKeyboardShortcutsMode(command);

        if (keys && keytrap) {
            keytrap.bind(
                keys,
                createHandlerMethod(
                    allowHotkeyOnTextFields,
                    isEnabled,

                    // Ribbon keytips are activated using the "Alt" key. In order to
                    // differentiate hotkey combinations that use "Alt" and "Alt"
                    // usage for Ribbon keytips (handled in `useCustomKeytipEvents.ts`), we
                    // need these keydown events to propagate.
                    hasAltKey(keys) ? false : stopPropagation,

                    preventDefault,
                    handler
                )
            );
        }
    }
}

const hasAltKey = (keys: Hotkeys): boolean => {
    return Array.isArray(keys) ? keys.some(entry => entry.includes('alt')) : keys.includes('alt');
};
